import React from "react";
import {
  View,
  Text,
  ImageBackground,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer";
import {
  ArrowLeftOnRectangleIcon,
  PhoneIcon,
  ShareIcon,
} from "react-native-heroicons/outline";
import { FontAwesome5 } from "@expo/vector-icons";
import { FIREBASE_AUTH } from "../firebaseConfig";
import { signOut } from "firebase/auth";
import { useAuth } from "../App";
import { languageDrawerDict } from "../constant/LanguageDict";
import { COLORS } from "../constant";
import { onShare } from "../utils/generalUtil";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Linking } from "react-native-web";
import { useQuiz } from "../useContext/useQuiz";
const dictAvatar = {
  none: require("../assets/images/none.png"),
  boy: require("../assets/images/boy.png"),
  girl: require("../assets/images/girl.png"),
};

const CustomDrawer = (props) => {
  const { currentUser } = useAuth();
  const { testVersion } = useQuiz();

  const handleSignOut = async () => {
    try {
      const auth = FIREBASE_AUTH;
      await AsyncStorage.removeItem("OnboardingRegisterScreen");
      const result = await signOut(auth);
      // console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ backgroundColor: "#8200d6" }}
      >
        <ImageBackground
          source={require("../assets/images/menu-bg.jpeg")}
          style={{ padding: 20 }}
        >
          <Image
            source={dictAvatar[currentUser?.settings?.avatar || "none"]}
            style={{
              height: 80,
              width: 80,
              borderRadius: 40,
              marginBottom: 10,
            }}
            className="bg-whiteBackground "
          />
          <View className="flex-row justify-start">
            <Text
              style={{
                color: "#eee",
                fontSize: 14,
                // fontFamily: 'Roboto-Medium',
                marginBottom: 5,
              }}
            >
              {currentUser?.email}
            </Text>
          </View>

          {/* <Text
            style={{
              color: "#fff",
              fontSize: 18,
              // fontFamily: 'Roboto-Medium',
              marginBottom: 5,
            }}
          >
            John Doe
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                color: "#fff",
                // fontFamily: 'Roboto-Regular',
                marginEnd: 5,
              }}
            >
              280 Coins
            </Text>
            <FontAwesome5 name="coins" size={14} color="#fff" />
          </View> */}
        </ImageBackground>
        <View style={{ flex: 1, backgroundColor: "#fff", paddingTop: 10 }}>
          <DrawerItemList {...props} />
        </View>
      </DrawerContentScrollView>
      <View style={{ padding: 20, borderTopWidth: 1, borderTopColor: "#ccc" }}>
        {Platform.OS != "web" && (
          <TouchableOpacity onPress={onShare} style={{ paddingVertical: 15 }}>
            <View
              className="flex-row items-start"
              style={
                Platform.OS == "web"
                  ? { flexDirection: "row-reverse" }
                  : { flexDirection: "row" }
              }
            >
              <ShareIcon size={22} color={COLORS.primary} />
              <Text
                style={{
                  fontSize: 15,
                  // fontFamily: 'Roboto-Medium',
                  marginStart: 5,
                }}
              >
                {languageDrawerDict["Tell a Friend"]}
              </Text>
            </View>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={handleSignOut}
          style={{ paddingVertical: 15 }}
        >
          <View
            className="items-start"
            style={
              Platform.OS == "web"
                ? { flexDirection: "row-reverse" }
                : { flexDirection: "row" }
            }
          >
            <ArrowLeftOnRectangleIcon size={22} color={COLORS.primary} />
            <Text
              style={{
                fontSize: 15,
                // fontFamily: 'Roboto-Medium',
                marginStart: 5,
              }}
            >
              {languageDrawerDict["Sign Out"]}
            </Text>
          </View>
        </TouchableOpacity>
        <View
          style={{ padding: 10, borderTopWidth: 1, borderTopColor: "#ccc" }}
        ></View>
        <TouchableOpacity
          onPress={() => {
            Linking.openURL("https://wa.me/972586519423");
          }}
        >
          <View
            style={{
              flexDirection: Platform.OS == "web" ? "row-reverse" : "row",
              alignItems: "center",
            }}
          >
            <FontAwesome5 name="whatsapp" size={22} color={COLORS.success} />
            <Text
              style={{
                fontSize: 15,
                // fontFamily: 'Roboto-Medium',
                marginLeft: 5,
              }}
            >
              {languageDrawerDict["Available on Whatsapp"]}
            </Text>
          </View>
          <View
            style={{
              flexDirection: Platform.OS == "web" ? "row-reverse" : "row",
            }}
          >
            <Text
              style={{
                fontSize: 15,
                fontWeight: 600,
                // fontFamily: 'Roboto-Medium',
                marginStart: 35,
              }}
            >
              0586519423
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default CustomDrawer;