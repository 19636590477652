import { Platform, Text } from "react-native";
import { mmlOptions } from "../constant/constants";
import MathJax from "react-native-mathjax";
import { View } from "react-native";

export const isMathQuestion = (text) => text && text.includes("$$");

function makeBoldLtr(str, styleText) {
  const parts = str.split("$$");
  const jsx = parts.map((part, index) => {
    if (index % 2 === 1) {
      return (
        <div
          style={{
            direction: "ltr",
            textAlign: Platform.web ? "left" : "right",
          }}
        >
          <span style={styleText == "question" ? { fontWeight: 600 } : null}>
            {part}
          </span>
        </div>
      );
    } else {
      return part;
    }
  });

  return jsx;
}

const dictStyle = {
  question: { flex: 1, fontSize: 20, lineHeight: 28, color: "#000000" },
  explanation: { fontSize: 18, lineHeight: 28, color: "#000000" },
};

const defaultStyle = {
  fontSize: 20,
  lineHeight: 28,
  color: "#000000",
  alignContent: "center",
  justifyContent: "center",
};
// style={styleText in dictStyle ? dictStyle[styleText] : defaultStyle}

export const displayQuestionMath = (text, styleText) => {
  return (
    <Text style={dictStyle[styleText]}>{makeBoldLtr(text, styleText)}</Text>
  );
};
/**
 * Replace special characters with similar English letters
 * @param {string} text - Input text containing special characters
 * @returns {string} - Text with special characters replaced by similar English letters
 */
export function replaceSpecialChars(text) {
  if (text === undefined || text === null) {
    console.warn("Warning: Input text is undefined or null");
    return "";
  }
  // Create a mapping of special characters to English letters
  const charMapping = {
    "○": "o", // Circle to lowercase 'o'
    "◊": "H", // Diamond to lowercase 'o'
    "∆": "A", // Triangle to uppercase 'A'
    "⌂": "A", // House to uppercase 'A'
    "□": "D", // Square to uppercase 'D'
  };

  // Replace each special character in the text
  let result = "";
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (char in charMapping) {
      result += charMapping[char];
    } else {
      result += char;
    }
  }

  return result;
}
